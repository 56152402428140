import React from 'react';
import {
    MDBEdgeHeader,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBAnimation, MDBCardBody, MDBCardTitle, MDBCard,
} from 'mdbreact';


const Chargement = () => {
    return (
        <>
            <MDBEdgeHeader color='black' className='sectionPage' />
            <MDBAnimation type='zoomIn' duration='500ms'>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md='6' className='mx-auto'>
                            <MDBCard cascade className='my-3 grey lighten-4'>
                                <MDBCardBody cascade className='text-left'>
                                    <MDBCardTitle>
                                        <strong>Chargement</strong><br/>
                                    </MDBCardTitle>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>
        </>
    );
};

export default Chargement;
