import React from 'react';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBCardGroup
} from 'mdbreact';
import './HomePage.css';
import SectionContainer from "../components/sectionContainer";
import axios from 'axios';
import CardLoadDebatValidate from "./CardLoadDebatValidate";
import NotFound from "./NotFound";
import Chargement from "./Chargement";

class Validate extends React.Component {
    scrollToTop = () => window.scrollTo(0, 0);
    state = {
        dateStart: "",
        dateEnd: "",
        mainSubject: "",
        dataResponse: "",
        status: 0,
        dabatList: "",
        dataSend: [],
        dataNonReplay: [],
        headers: {
            headers: {
                "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                'Content-Type': 'application/ld+json',
            }
        }

    };

    componentDidMount() {
        this.handleLoad();
    }

    handleLoad = e => {
        const headers = {
            headers: {
                "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                'Content-Type': 'application/ld+json',
            }
        }
        axios.get(process.env.REACT_APP_BASE_URL + '/admin/lives-to-validate', headers)
            .then(res => {
                let rows = res.data.map((row, key) => ({
                    ...row,
                    isChecked: false

                }));
                rows.forEach(element => {
                    if (element.replayable) {
                        element.isChecked = true
                    }
                });

                this.setState({
                    dataResponse: rows,
                    status: 200
                })
            }).catch(error => {
            console.log(error, "error get")
            this.setState({
                status: 400
            })
        })
    }
    handleSubmit = event => {
        event.preventDefault();
        const headers = this.state.headers;
        this.state.dataResponse.forEach(element => {
            if (element.isChecked) {
                this.state.dataSend.push(element.id)
            } else {
                this.state.dataNonReplay.push(element.id)
            }

        });
        const data = {"liveTalkToReplay": this.state.dataSend, "liveTalkToNoReplay": this.state.dataNonReplay}
        const data1 = JSON.stringify(data);
        axios.post(process.env.REACT_APP_BASE_URL + '/define-lives-replayable', data1, headers)
            .then(res => {
                console.log(res.data, res, " data");
                this.setState({
                    status: 200,
                    dateStart: "",
                    dateEnd: "",
                    mainSubject: "",
                    dataResponse: ""
                })
            }).catch(error => {
            this.setState({
                status: 400
            })
        })
    }
    handleChange = event => {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    render() {
        if (this.state.status === 200) {
            const debatList = Object.keys(this.state.dataResponse)
                .map(key => <CardLoadDebatValidate
                    key={key}
                    id={this.state.dataResponse[key].id}
                    posterUrl={this.state.dataResponse[key].posterUrl}
                    mainSubject={this.state.dataResponse[key].mainSubject}
                    replayable={this.state.dataResponse[key].replayable}
                    add={this.add}
                    remove={this.remove}
                    isChecked={this.state.dataResponse[key].isChecked}
                    dateStart={this.state.dataResponse[key].startDate}
                    duration={this.state.dataResponse[key].duration}
                    creatorId={this.state.dataResponse[key].creatorId}
                    creatorWillBeReferee={this.state.dataResponse[key].creatorWillBeReferee}
                    creationDate={this.state.dataResponse[key].creationDate}
                    format={this.state.dataResponse[key].format}

                />)

            return (
                <>
                    <div className='mt-3 mb-5'>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md='12' className='mt-4'><br/><br/>
                                    <SectionContainer header='Validations des débats'>
                                        <MDBCardGroup>
                                            {debatList}
                                        </MDBCardGroup>
                                    </SectionContainer>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </>
            );
        }
        if (this.state.status === 0) {
            return (
                <Chargement/>
            )
        }
        return (
            <NotFound/>
        )

    }
}

export default Validate;
