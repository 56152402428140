import React from 'react';
import {Route, Switch} from 'react-router-dom';
import NotFound from "./pages/NotFound"
import HomePage from './pages/HomePage';
import Validate from "./pages/Validate";
import Arbitre from "./pages/arbitre";
import SaisieNews from "./pages/SaisieNews";
class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path='/' component={HomePage}/>
                <Route exact path='/validation-debat-utilisateur' component={Validate}/>
                <Route exact path='/selection-arbitre-debat' component={Arbitre}/>
                <Route exact path='/saisie-news' component={SaisieNews}/>
                <Route exact component={NotFound}/>

                <Route
                    render={function () {
                        return <NotFound/>;
                    }}
                />
            </Switch>
        );
    }
}

export default Routes;
