import React from 'react';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBCardGroup,
} from 'mdbreact';
import './HomePage.css';
import SectionContainer from "../components/sectionContainer";
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


class SaisieNews extends React.Component {
    scrollToTop = () => window.scrollTo(0, 0);
    state = {
        info: undefined,
        messageUpload: undefined,
        urlMedia: undefined,
        nameAuteur: undefined,
        typeArticle: undefined,
        titreArticle: undefined,
        file: undefined,
        message: undefined,
        processing: false,
        dateStart: "",
        dateEnd: "",
        mainSubject: "",
        dataResponse: "",
        status: 0,
        dabatList: "",
        dataSend: [],
        dataNonReplay: [],
        editorState: EditorState.createEmpty(),
        checkOption: undefined,
        dataUrl: undefined,
        publicationDate: undefined,
        headers: {
            headers: {
                "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                'Content-Type': 'application/ld+json',
            }
        }
    };


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleSubmit = e => {
        e.preventDefault()
        this.setState({
            processing: true
        })
        const creationDate = parseInt((new Date().getTime()) / 1000);
        if (this.state.file !== undefined && this.state.messageUpload !== undefined) {
            return this.setState({processing: false, message: this.state.messageUpload})
        }
        this.setState({message: undefined});
        const headers = this.state.headers
        const publicationDate =this.state.publicationDate===undefined?"" : (new Date(this.state.publicationDate).getTime()) / 1000;
        if (this.state.file !== undefined) {
            const extension = this.state.file.name.split('.');
            const fileExt = extension[1];
            const nameFile = "blablafoot_" + creationDate;

            let dataUpload = {
                name: nameFile,
                type: "." + fileExt
            }
            let formData = new FormData();
            axios.post(process.env.REACT_APP_BASE_URL + '/admin/news/get-presigned-url-for-news-media', dataUpload, headers)
                .then(res => {
                    const data = JSON.parse(res.data.body);
                    const fields = data.data.fields;
                    Object.keys(fields).forEach(element => {
                        formData.append(element, fields[element])
                    })
                    formData.append('file', this.state.file);
                    this.setState({
                        dataUrl: data.data.url
                    })
                    axios.post(data.data.url, formData)
                        .then(res => {
                            const data = {
                                creationDate: creationDate,
                                title: this.state.titreArticle,
                                authorName: this.state.nameAuteur,
                                content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                                mediaURL: this.state.dataUrl + "/" + nameFile + "." + fileExt,
                                type: this.state.typeArticle,
                                mediaType: "image",
                                publicationDate: publicationDate

                            }

                            const data1 = JSON.stringify(data)
                            axios.post(process.env.REACT_APP_BASE_URL + '/admin/news/create-or-update-news', data1, headers)
                                .then(res => {
                                    this.setState({
                                        titreArticle: "",
                                        nameAuteur: "",
                                        editorState: EditorState.createEmpty(),
                                        typeArticle: "",
                                        info: "Votre article a été enregistré avec succès",
                                        file: undefined,
                                        checkOption: undefined,
                                        publicationDate: "",
                                        processing: false

                                    })
                                }).catch(error => {
                            })
                        }).catch(error => {
                    })

                }).catch(error => {

            })
        } else {
            const data = {
                creationDate: creationDate,
                title: this.state.titreArticle,
                authorName: this.state.nameAuteur,
                content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                mediaURL: this.state.urlMedia,
                type: this.state.typeArticle,
                mediaType: "video",
                publicationDate: publicationDate

            };
            const data1 = JSON.stringify(data);
            axios.post(process.env.REACT_APP_BASE_URL + '/admin/news/create-or-update-news', data1, headers)
                .then(res => {
                    this.setState({
                        titreArticle: "",
                        nameAuteur: "",
                        editorState: EditorState.createEmpty(),
                        typeArticle: "",
                        urlMedia: "",
                        info: "Votre article a été enregistré avec succès",
                        mediaURL: undefined,
                        checkOption: undefined,
                        publicationDate: "",
                        processing: false

                    })
                }).catch(error => {
            })
        }

    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    onChange = e => {
        this.setState({file: e.target.files[0]})
    }
    handleUpload = e => {
        e.preventDefault();
        if (this.state.file !== undefined) {
            const extension = this.state.file.name.split('.');
            const fileExt = extension[1];
            if (fileExt === "jpg" || fileExt === "JPG" || fileExt === "jpeg" || fileExt === "JPEG" || fileExt === "png" || fileExt === "PNG") {
                return this.setState({messageUpload: undefined})

            }
            return this.setState({messageUpload: "Votre fichier n'est pris en compte."})
        }

    }

    render() {

        return (
            <>
                <div className='mt-3 mb-5'>
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol md='12' className='mt-12'>
                                <h1 className="text-success">
                                    <strong>{this.state.info === undefined ? "" : this.state.info}</strong>
                                </h1>
                                <span className="text-danger">
                                        {this.state.message === undefined ? "" : this.state.message}
                                     </span><br/><br/>
                                <SectionContainer>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className='form-group'>
                                            <label htmlFor='formGroupExampleInput'>Titre</label>
                                            <input type='text' className='form-control' id='formGroupExampleInput'
                                                   placeholder="Le titre de l'article"
                                                   name="titreArticle"
                                                   required
                                                   value={this.state.titreArticle}
                                                   onChange={this.handleChange}

                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='formGroupExampleInput'>Type</label>
                                            <select className='custom-select bMDBRowser-default'
                                                    name="typeArticle"
                                                    required
                                                    value={this.state.typeArticle}
                                                    onChange={this.handleChange}
                                            >
                                                <option selected disabled value=''>Type de votre article</option>
                                                <option value="news">news</option>
                                                <option value="article">article</option>
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='formGroupExampleInput2'>Nom</label>
                                            <input type='text' className='form-control'
                                                   id='formGroupExampleInput2'
                                                   placeholder="Le nom de l'auteur de l'article"
                                                   name="nameAuteur"
                                                   required
                                                   value={this.state.nameAuteur}
                                                   onChange={this.handleChange}

                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='formGroupExampleInput2'>content</label>

                                            <Editor
                                                editorState={this.state.editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />

                                        </div>
                                        <MDBRow>
                                            <MDBCol md='6'>
                                                <label htmlFor='formGroupExampleInput2'>
                                                    Saisir Url de votre illustration
                                                </label>
                                                <MDBInput
                                                    name="checkOption"
                                                    type="radio"
                                                    value="url"
                                                    onChange={this.handleChange}

                                                />
                                            </MDBCol>
                                            <MDBCol md='6'>
                                                <label htmlFor='formGroupExampleInput2'>
                                                    Selectionner votre illustration(image) depuis votre PC
                                                </label>
                                                <MDBInput
                                                    name="checkOption"
                                                    value="image"
                                                    type="radio"
                                                    onChange={this.handleChange}
                                                />
                                            </MDBCol>
                                        </MDBRow><br/><br/><br/>

                                        {this.state.checkOption === "url" ?
                                            <div className='form-group'>
                                                <label htmlFor='formGroupExampleInput2'>Url de votre
                                                    illustration</label>
                                                <input type='text' className='form-control'
                                                       id='formGroupExampleInput2'
                                                       name="urlMedia"
                                                       onChange={this.handleChange}
                                                       value={this.state.urlMedia}
                                                       placeholder="L'URL d'accès au média de l'article( soit l'URL  youtube d'une vidéo )"/>
                                            </div>
                                            : ""

                                        }
                                        {this.state.checkOption === "image" ?
                                            <div className='form-group'>
                                                <label htmlFor='formGroupExampleInput2'>Media image</label>
                                                <input type='file' className='form-control'
                                                       id='formGroupExampleInput2'
                                                       name="file" onChange={this.onChange}
                                                       onBlur={this.handleUpload}
                                                       placeholder="Le nom de l'auteur de l'article"/>
                                                <span className="text-danger">
                                                    <strong>{this.state.messageUpload === undefined ? "" : this.state.messageUpload}</strong>
                                                </span>
                                            </div>

                                            : ""}
                                        <div className='form-group'>
                                            <label htmlFor='formGroupExampleInput2'>Date Publication(A préciser uniquement si vous voulez le rendre disponible plus tard) </label>
                                            <input type='datetime-local' className='form-control'
                                                   id='formGroupExampleInput2'
                                                   name="publicationDate"
                                                   value={this.state.publicationDate}
                                                   onChange={this.handleChange}
                                                  />
                                        </div>
                                        {/*  <div className='form-group'>
                                                <label htmlFor='formGroupExampleInput2'>Visualisation du content</label>
                                            <textarea
                                                className='form-control'
                                                disabled
                                                value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}
                                            />
                                            </div> */}
                                        <MDBBtn color='primary' type='submit'>
                                            {this.state.processing ? "En cours de chargement" : "Valider des debats"}
                                        </MDBBtn>
                                    </form>
                                </SectionContainer>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </>
        );

    }
}

export default SaisieNews;
