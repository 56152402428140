import React, {Component} from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavItem,
    MDBFooter,
    MDBNavLink,
    MDBTooltip,
    MDBView,
} from 'mdbreact';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './Routes';

class App extends Component {
    state = {
        collapseID: ''
    };

    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));

    closeCollapse = collID => () => {
        const {collapseID} = this.state;
        window.scrollTo(0, 0);
        collapseID === collID && this.setState({collapseID: ''});
    };

    render() {
        const overlay = (
            <div
                id='sidenav-overlay'
                style={{backgroundColor: 'transparent'}}
                onClick={this.toggleCollapse('mainNavbarCollapse')}
            />
        );

        const {collapseID} = this.state;

        return (
            <Router>
                <div className='flyout'>
                    <MDBNavbar color='black' dark expand='md' fixed='top' scrolling>
                        <MDBNavbarBrand href='/' className='py-0 font-weight-bold'>
                            <MDBView>
                                <img
                                    src='../images/slide/blabla.jpg'
                                    alt='Second slide'
                                    height='40px'
                                    width="40px"
                                />
                            </MDBView>

                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            onClick={this.toggleCollapse('mainNavbarCollapse')}
                        />
                        <MDBCollapse id='mainNavbarCollapse' isOpen={collapseID} navbar>
                            <MDBNavbarNav right>
                                <MDBNavItem>
                                    <MDBNavLink
                                        exact
                                        to='/'
                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                    >
                                        <strong> Replayable des débats</strong>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink
                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                        to='/validation-debat-utilisateur'
                                    >
                                        <strong>Validation des débats des utilisateurs </strong>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink
                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                        to='/selection-arbitre-debat'
                                    >
                                        <strong>Sélection des arbitres des débats</strong>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink
                                        onClick={this.closeCollapse('mainNavbarCollapse')}
                                        to='/saisie-news'
                                    >
                                        <strong>Saisie des news</strong>
                                    </MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                    {collapseID && overlay}
                    <main style={{marginTop: '4rem'}}>
                        <Routes/>
                    </main>
                    <MDBFooter color='black'>
                        <p className='footer-copyright mb-0 py-3 text-center'>
                            <a href='#'></a>
                        </p>

                    </MDBFooter>
                </div>
            </Router>
        );
    }
}

export default App;
