import React, {Component} from 'react';

import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol, MDBRow,
} from 'mdbreact';
import axios from "axios";
import {db} from "../utils/firebase-config.js";
import moment from 'moment';

class CardArbitre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: undefined,
            emailArbitre: undefined,
            username: undefined,
            users: [],
            isClick: false,
            checked: false,
            file: null,
            idDebat: undefined,
            headers: {
                headers: {
                    "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                    'Content-Type': 'application/ld+json',
                }
            }
        };
    }


    handleClick = () => {
        this.setState(
            {
                isClick: !this.state.isClick
            }
        )
    };
    handleCheckboxChange = event =>
        this.setState({checked: event.target.checked})
    onChange = e => {
        this.setState({file: e.target.files[0]})
    }
    handleChangeData = id => {
        this.setState({
            idDebat: id.id
        })
    }

    handleSearch = event => {
        let username = event.target.value;
        db.collection("users")
            .where("userName", ">=", username).where("userName", "<=", username + '\uf8ff')
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                this.setState({users: data})
            });
    }
    handleChange = event => {
        const {name, value} = event.target
        this.setState({[name]: value})
    }
    handleValidate = event => {
        event.preventDefault();
        this.setState({
            processing: true
        })
        if (this.state.emailArbitre === undefined || this.state.idDebat === undefined) {
            return this.setState({message: "Vous choisir un arbtre de débat pour effectuer cette operation."})
        }
        const data = {
            "userId": this.state.emailArbitre,
            "liveId": this.state.idDebat,
            "role": "referee",
            "status": "approved"
        }
        const data1 = JSON.stringify(data);
        axios.post(process.env.REACT_APP_BASE_URL + '/userlive', data1, this.state.headers)
            .then(res => {
                console.log(res, "repose userlive")
                this.setState({
                    status: 200,
                    idDebat: undefined,
                    liveId: undefined,
                    message: "L'animateur de ce débat a bien été affecté.",
                    users: [],
                    processing: false,

                })

            }).catch(error => {
            console.log(error, "error")
            this.setState({
                status: 400,
                processing: false,
                message: "Erreur lors du traitement de votre débat."
            })
        })
    }

    render() {
        const {id, posterUrl, mainSubject, dateStart} = this.props;
        let date = new Date(dateStart * 1000);
        return (
            <MDBCol md='6'>
                <h3 className="text-success">
                    <strong>{this.state.message === undefined ? "" : this.state.message}</strong>
                </h3>
                <MDBCard onClick={() => this.handleChangeData({id})}>
                    <MDBCardImage
                        src={posterUrl}
                        top
                        hover
                        overlay='white-slight'
                    />
                    <MDBCardBody>
                        <MDBCardTitle tag='h5'>
                            Date de debat: {moment(date).format("DD/MM/YYYY HH:mm")}<br/>
                        </MDBCardTitle>
                        <MDBCardText>
                            {mainSubject}
                        </MDBCardText>
                        <MDBRow>
                            <MDBCol md='12'>
                                <label
                                    htmlFor='defaultFormLoginEmailEx'
                                    className='grey-text'
                                >
                                    Nom utilisateur
                                </label>
                                <input
                                    type='text'
                                    name="username"
                                    placeholder=" Recherchez un utilisateur par nom…"
                                    className='form-control'
                                    onKeyUp={this.handleSearch}
                                /><br/>
                            </MDBCol>
                            <MDBCol md='12'>
                                {this.state.users.length === 0 ? "" :
                                    <table class="table table-sm">
                                        <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Action</th>
                                            <th scope="col">username</th>
                                            <th scope="col">email</th>
                                            <th scope="col">Picture</th>


                                        </tr>
                                        </thead>
                                        <tbody>

                                        {this.state.users.map(liste => (
                                            <tr>
                                                <td>
                                                    <input
                                                        type='radio'
                                                        name="emailArbitre"
                                                        onChange={this.handleChange}
                                                        className='form-control'
                                                        value={liste.email}


                                                    />
                                                </td>
                                                <td>{liste.userName}</td>
                                                <td>{liste.email}</td>
                                                <td><MDBCardImage
                                                    src={liste.picture}
                                                    top
                                                    hover
                                                    overlay='white-slight'
                                                />
                                                </td>
                                            </tr>
                                        ))
                                        }
                                        </tbody>

                                        <MDBBtn color='primary'
                                                type='submit'
                                                onClick={this.handleValidate}
                                        >
                                            {this.state.processing ? "en cours de chargement" : "Choisir"}
                                        </MDBBtn>

                                    </table>
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard><br/>

            </MDBCol>


        );
    }
}

export default CardArbitre;
