import React from 'react';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBCardGroup
} from 'mdbreact';
import './HomePage.css';
import SectionContainer from "../components/sectionContainer";
import axios from 'axios';
import CardReplay from "./CardReplay";

class HomePage extends React.Component {
    scrollToTop = () => window.scrollTo(0, 0);
    state = {
        message: undefined,
        processing: false,
        dateStart: "",
        dateEnd: "",
        mainSubject: "",
        dataResponse: "",
        status: 0,
        dabatList: "",
        dataSend: [],
        dataNonReplay: [],
        headers: {
            headers: {
                "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                'Content-Type': 'application/ld+json',
            }
        }

    };

    add = (id, checked) => {
        let data = this.state.dataResponse;
        data.forEach(element => {
            if (element.id === id) {
                element.isChecked = !checked
            }
            this.setState({data})
        });

    }

    handleLoad = e => {
        e.preventDefault()
        this.setState({
            processing: true
        })
        const dateStart = (new Date(this.state.dateStart).getTime()) / 1000;
        const dateEnd = (new Date(this.state.dateEnd).getTime()) / 1000;
        const data = {"mainSubject": this.state.mainSubject, "startDate": dateStart, "endDate": dateEnd}
        const headers = this.state.headers
        const data1 = JSON.stringify(data)
        axios.post(process.env.REACT_APP_BASE_URL + '/admin/replay/lives-for-replayability', data1, headers)
            .then(res => {
                const rows = res.data.map((row, key) => ({
                    ...row,
                    isChecked: false

                }));
                rows.forEach(element => {
                    if (element.replayable) {
                        element.isChecked = true
                    }
                });

                this.setState({
                    dataResponse: rows,
                    status: 200,
                    processing: false
                })
            }).catch(error => {
            this.setState({
                status: 400,
                processing: false,
                message: "Erreur lors de la recuperation des débats."
            })
        })
    }
    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            processing: true
        })
        const headers = this.state.headers;
        this.state.dataResponse.forEach(element => {
            if (element.isChecked) {
                this.state.dataSend.push(element.id)
            } else {
                this.state.dataNonReplay.push(element.id)
            }

        });
        const data = {"liveTalkToReplay": this.state.dataSend, "liveTalkToNoReplay": this.state.dataNonReplay}
        const data1 = JSON.stringify(data);
        axios.post(process.env.REACT_APP_BASE_URL + '/admin/replay/define-lives-replayable', data1, headers)
            .then(res => {
                this.setState({
                    status: 200,
                    dateStart: "",
                    dateEnd: "",
                    mainSubject: "",
                    dataResponse: "",
                    processing: false,
                    message: "Les débats ont été validé avec succès"
                })
            }).catch(error => {
            this.setState({
                status: 400,
                processing: false,
                message: "Erreur lors de la validation des débats."
            })
        })
    }
    handleChange = event => {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    render() {
        if (this.state.dataResponse !== "") {
            const debatList = Object.keys(this.state.dataResponse)
                .map(key => <CardReplay
                    key={key}
                    id={this.state.dataResponse[key].id}
                    posterUrl={this.state.dataResponse[key].posterUrl}
                    mainSubject={this.state.dataResponse[key].mainSubject}
                    replayable={this.state.dataResponse[key].replayable}
                    add={this.add}
                    remove={this.remove}
                    isChecked={this.state.dataResponse[key].isChecked}
                    dateStart={this.state.dataResponse[key].startDate}
                />)

            return (
                <>
                    <div className='mt-3 mb-5'>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md='12' className='mt-4'><br/><br/>
                                    <SectionContainer>
                                        <form onSubmit={this.handleLoad}>
                                            <MDBRow>
                                                <MDBCol md='4'>
                                                    <MDBInput
                                                        name="mainSubject"
                                                        value={this.state.mainSubject}
                                                        onChange={this.handleChange}
                                                        type='text'
                                                        label='Le titre du débat contient… '
                                                    />
                                                </MDBCol>
                                                <MDBCol md='4'>
                                                    <MDBInput
                                                        name="dateStart"
                                                        value={this.state.dateStart}
                                                        onChange={this.handleChange}
                                                        type='date'
                                                        label='Date inférieur'
                                                    />
                                                </MDBCol>
                                                <MDBCol md='4'>
                                                    <MDBInput
                                                        name="dateEnd"
                                                        type="date"
                                                        value={this.state.dateEnd}
                                                        onChange={this.handleChange}
                                                        label='Date supérieur'
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBBtn color='primary' type='submit'>
                                                {this.state.processing ? "En cours de chargement" : "Afficher des débats"}
                                            </MDBBtn>
                                        </form>
                                    </SectionContainer>
                                    <SectionContainer header='Replayable des débats'>
                                        <MDBCardGroup>
                                            {debatList}
                                        </MDBCardGroup>
                                        <form className='join-form' onSubmit={this.handleSubmit}>
                                            <div className="button-vd mdl-card__actions mdl-card--border">
                                                <MDBBtn color='primary' type='submit'>
                                                    {this.state.processing ? "En cours de chargement" : "valider des débats"}
                                                </MDBBtn>
                                            </div>
                                        </form>
                                    </SectionContainer>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className='mt-3 mb-5'>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md='12' className='mt-4'><br/><br/>
                                    <SectionContainer header='Replayable des débats '>
                                        <h1 className="text-success">
                                            <strong>{this.state.message === undefined ? "" : this.state.message}</strong>
                                        </h1>
                                        <form onSubmit={this.handleLoad}>
                                            <MDBRow>
                                                <MDBCol md='4'>
                                                    <MDBInput
                                                        name="mainSubject"
                                                        value={this.state.mainSubject}
                                                        onChange={this.handleChange}
                                                        type='text'
                                                        label='Le titre du débat contient… '

                                                    />
                                                </MDBCol>
                                                <MDBCol md='4'>
                                                    <MDBInput
                                                        name="dateStart"
                                                        value={this.state.dateStart}
                                                        onChange={this.handleChange}
                                                        type='date'
                                                        label='Date inférieur'
                                                    />
                                                </MDBCol>
                                                <MDBCol md='4'>
                                                    <MDBInput
                                                        name="dateEnd"
                                                        type="date"
                                                        value={this.state.dateEnd}
                                                        onChange={this.handleChange}
                                                        label='Date supérieur'
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBBtn color='primary' type='submit'>
                                                {this.state.processing ? "En cours de chargement" : "Afficher des débats"}
                                            </MDBBtn>
                                        </form>
                                    </SectionContainer>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </>
            );
        }
    }
}

export default HomePage;
