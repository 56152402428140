import React from 'react';
import {
    MDBEdgeHeader,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBAnimation, MDBView, MDBCardBody, MDBCardTitle, MDBCard,
} from 'mdbreact';


const NotFound = () => {
    return (
        <>
            <MDBEdgeHeader color='black' className='sectionPage'/>
            <MDBAnimation type='zoomIn' duration='500ms'>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md='6' className='mx-auto'>
                            <MDBCard cascade className='my-3 grey lighten-4'>
                                <MDBView>
                                    <img
                                        className='d-block w-100'
                                        src='../images/slide/blabla.jpg'
                                        alt='Second slide'
                                        top

                                    />
                                </MDBView>
                                <MDBCardBody cascade className='text-left'>
                                    <MDBCardTitle>
                                        <strong>Êtes-vous perdu ? Un souci ? </strong><br/>
                                        <strong>N'hésitez pas à nous contacter à l'adresse : </strong><br/>
                                        <span>
                                       <a href="jira@timsystem.atlassian.net">jira@timsystem.atlassian.net</a>
                                </span>
                                    </MDBCardTitle>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBAnimation>
        </>
    );
};

export default NotFound;
