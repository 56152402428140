import React, {Component} from 'react';

import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBRow,
    MDBInput,

} from 'mdbreact';
import SectionContainer from '../components/sectionContainer';
import axios from "axios";
import moment from 'moment'

class CardLoadDebatValidate extends Component {
    scrollToTop = () => window.scrollTo(0, 0);
    state = {
        processing: false,
        reason: undefined,
        statusDebat: "attente",
        isClick: false,
        checked: false,
        file: null,
        idDebat: undefined,
        headers: {
            headers: {
                "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                'Content-Type': 'application/ld+json',
            }
        }
    };

    componentDidMount() {
        const {replayable} = this.props;
        if (replayable) {
            this.setState({
                checked: true,
            })
        }
    }

    handleClick = () => {
        this.setState(
            {
                isClick: !this.state.isClick
            }
        )
    };
    handleCheckboxChange = event =>
        this.setState({checked: event.target.checked})
    onChange = e => {
        this.setState({file: e.target.files[0]})
    }
    handleChangeData = id => {
        this.setState({
            idDebat: id
        })
    }
    handleChange = event => {
        const {name, value} = event.target
        this.setState({[name]: value})
    }
    handleValidate = event => {
        event.preventDefault();
        this.setState({
            processing: true
        })
        alert("Voulez vous confirmer cette opération?")
        const reason = this.state.reason === undefined ? "" : this.state.reason;
        const statusDebat = this.state.statusDebat === "validate" ? true : false;
        const idDebat = this.state.idDebat === undefined ? "" : this.state.idDebat.id;
        const data = {
            "liveId": idDebat,
            "validate": statusDebat,
            "reason": reason
        }
        const data1 = JSON.stringify(data);
        axios.post(process.env.REACT_APP_BASE_URL + '/admin/live/validate', data1, this.state.headers)
            .then(res => {
                this.setState({
                    status: 200,
                    idDebat: undefined,
                    reason: undefined,
                    statusDebat: "attente",
                    processing: false,
                    message: "Votre débat a été traité avec succès."
                })
                window.location.replace("/validation-debat-utilisateur");
            }).catch(error => {
            this.setState({
                status: 400,
                processing: false,
                message: "Erreur lors du traitement de votre débat."
            })
        })
    }

    render() {
        const {id, posterUrl, mainSubject, dateStart, duration, creatorId, creatorWillBeReferee, creationDate, format} = this.props;
        var date = new Date(dateStart * 1000);
        var creteDate = new Date(creationDate * 1000);
        return (
            <MDBCol md='6'>
                <MDBCard>
                    <MDBCardImage
                        src={posterUrl}
                        top
                        hover
                        overlay='white-slight'
                    />
                    <MDBCardBody>
                        <MDBCardTitle tag='h5'>
                            Date proposée pour le débat: {moment(date).format("DD/MM/YYYY HH:mm")}<br/>
                            Durée: {duration + "min"}<br/>
                            Mail du créateur : {creatorId}<br/>
                            Format: {format}<br/>
                            {creatorWillBeReferee ? "Créateur veut être animateur":"Créateur ne veut pas être animateur"}<br/>
                            Date de création du débat: {moment(creteDate).format("DD/MM/YYYY HH:mm")}<br/>
                        </MDBCardTitle>
                        <MDBCardText>
                         SUJET:  {mainSubject}
                        </MDBCardText>
                        <SectionContainer>
                            <form onSubmit={this.handleValidate}>
                                <MDBRow>
                                    <MDBCol md='4'>
                                        <label className='grey-text'>
                                            En attente
                                        </label>
                                        <MDBInput
                                            name="statusDebat"
                                            type="radio"
                                            value="attente"
                                            onChange={this.handleChange}


                                        />
                                    </MDBCol>
                                    <MDBCol md='4'>
                                        <label className='grey-text'>
                                            valider
                                        </label>
                                        <MDBInput
                                            name="statusDebat"
                                            value="validate"
                                            type="radio"
                                            onChange={this.handleChange}

                                        />
                                    </MDBCol>
                                    <MDBCol md='4'>
                                        <label className='grey-text'>
                                            Non valider
                                        </label>
                                        <MDBInput
                                            name="statusDebat"
                                            type="radio"
                                            value="nonValidate"
                                            onChange={this.handleChange}

                                        />
                                    </MDBCol>
                                </MDBRow><br/><br/><br/>
                                {this.state.statusDebat !== "attente" ?
                                    <MDBRow>
                                        <MDBCol md='12'>
                                            <label
                                                htmlFor='defaultFormLoginEmailEx'
                                                className='grey-text'
                                            >
                                                {this.state.statusDebat === "validate" ? "Raison(facultatif)" : "Raison"}
                                            </label>
                                            <input
                                                type='text'
                                                name="reason"
                                                placeholder={this.state.statusDebat === "validate" ? "" : "Raison de votre refus de  validation"}
                                                onChange={this.handleChange}
                                                className='form-control'
                                                required={this.state.statusDebat === "nonValidate"}
                                            />
                                        </MDBCol>
                                        <MDBCol md='12'>
                                            <MDBBtn color='primary' type='submit'
                                                    onClick={() => this.handleChangeData({id})}>
                                                {this.state.processing ? "en cours de chargement" : "Valider"}
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                    : ""}
                            </form>
                        </SectionContainer>
                    </MDBCardBody>
                </MDBCard><br/><br/>

            </MDBCol>


        );
    }
}

export default CardLoadDebatValidate;
