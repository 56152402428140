import React, {Component} from 'react';

import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
} from 'mdbreact';
import axios from "axios";
import moment from 'moment'

class CardReplay extends Component {
    scrollToTop = () => window.scrollTo(0, 0);
    state = {
        messageUploade: undefined,
        isClick: false,
        checked: false,
        file: null,
        idDebat: undefined,
        headers: {
            headers: {
                "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
                'Content-Type': 'application/ld+json',
            }
        }
    };

    componentDidMount() {
        const {replayable} = this.props;
        if (replayable) {
            this.setState({
                checked: true,
            })
        }
    }

    handleClick = () => {
        this.setState(
            {
                isClick: !this.state.isClick
            }
        )
    };
    handleCheckboxChange = event =>
        this.setState({checked: event.target.checked})
    onChange = e => {
        this.setState({file: e.target.files[0]})
    }
    handleChangeData = id => {
        this.setState({
            idDebat: id
        })
    }
    handleUpload = event => {
        event.preventDefault()
        let data = {
            name: this.state.idDebat.id,
            type: ".mp3"
        }
        console.log(data, "data upload")
        let formData = new FormData();
        axios.post(process.env.REACT_APP_BASE_URL + '/admin/replay/get-presigned-url-for-upload', data, this.state.headers)
            .then(res => {

                const data = JSON.parse(res.data.body);
                const fields = data.data.fields;
                Object.keys(fields).forEach(element => {
                    formData.append(element, fields[element])
                })
                formData.append('file', this.state.file);
                console.log(data.data.url, "data url")
                axios.post(data.data.url, formData)
                    .then(res => {
                        console.log(res, " res data")
                        this.setState({
                            messageUploade: "Votre fichier a été envoyé avec succès."
                        })
                    }).catch(error => {
                    this.setState({
                        status: 400,
                        messageUploade: "Votre fichier n'a pas été envoyé, merci de reprendre la procedure."
                    })
                })

            }).catch(error => {
            console.log(error, "error  url")
            this.setState({
                status: 400
            })
        })
    }

    render() {
        const {id, posterUrl, add, mainSubject, isChecked, dateStart} = this.props;
        let date = new Date(dateStart * 1000);
        return (
            <MDBCol md='4' >
                <MDBCard >
                    <h3 className="text-success">
                        <strong>{this.state.messageUploade === undefined ? "" : this.state.messageUploade}</strong>
                    </h3>
                    <MDBCardImage
                        src={posterUrl}
                        top
                        hover
                        overlay='white-slight'
                    />
                    <MDBCardBody>
                        <MDBCardTitle tag='h5'>
                            Date de debat: {moment(date).format("DD/MM/YYYY HH:mm")}<br/>
                        </MDBCardTitle>
                        <MDBCardText>
                            {mainSubject}
                        </MDBCardText>
                        <MDBBtn color='light-blue' size='md'>
                            <input type="checkbox" checked={this.state.checked}
                                   onClick={() => (this.handleClick(), add(id, this.state.checked))}
                                   onChange={this.handleCheckboxChange}
                                   className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"/>
                            {isChecked ?
                                <div>
                                    <form onSubmit={this.handleUpload}>
                                        <input type="file" name="file" onChange={this.onChange}
                                               className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"/>
                                        <button type="submit" className="form-control btn-primary"
                                                onClick={() => this.handleChangeData({id})}>Envoyer
                                        </button>


                                    </form>
                                </div>
                                :
                                ""
                            }
                        </MDBBtn>
                    </MDBCardBody>
                </MDBCard><br/>

            </MDBCol>


        );
    }
}

export default CardReplay
