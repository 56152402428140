import firebase from 'firebase'
import axios from 'axios';

const headers = {
    headers: {
        "x-api-key": "L7QF50ujQm57MqDJxBB789rUGhkJ0FvB3fYOqYrl",
        'Content-Type': 'application/ld+json',
    }
};
let db; 
 axios.get(process.env.REACT_APP_BASE_URL + '/admin/properties/get-firebase-key', headers)
            .then(res => {
                const data = res.data;
                const firebaseApp = firebase.initializeApp({
                    apiKey: data.apiKey,
                    authDomain: data.authDomain,
                    databaseURL: data.databaseURL,
                    projectId: data.projectId,
                    storageBucket: data.storageBucket,
                    messagingSenderId: data.messagingSenderId,
                    appId: data.appId,
                    measurementId: data.measurementId
                });
                 db = firebaseApp.firestore();  
            }).catch(error => {
             console.log("Ereur lors de la récuperation de firebase-key");
        }) 
   
export { db };
